<template>

  <div>
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section">
        <div class="panel panel-default">
          <div class="panel-body ">
            <div class=" owner-name   ">
              <span class="t18" style="font-weight: 600" v-html="$auth.user().name"></span>
              <small class="small-text pull-right t16">
                <router-link style="color: #6092df ;" class="popup-edit-account" to="/edit-profile">{{$t('button_edit')}}</router-link>
              </small>
            </div>
            <div class=" owner-email t16 cl-78838c pt-10 fw-normal ">{{ $auth.user().email}}</div>

            <div class="owner-dob pt-5 t16 cl-78838c fw-normal">
              <div v-if="birthday != '' ">
                <span v-if="!isBirthday"><img
                  src="https://static.phdvasia.com/mx/icons/black%403x.png" width="24"> {{birthday}} {{$t('history.until_your_birthday')}}</span>
                <span v-else><i class="fa fa-exclamation-circle"></i> {{$t('history.title_your_birthday')}}</span>
              </div>
              <div v-else>
                <div class="mobile-only"><img
                  src="https://static.phdvasia.com/mx/icons/black%403x.png" width="24">
                  <router-link style="color: #6092df ;" class="popup-edit-account" to="/edit-profile">{{$t('history.title_can_your_birthday')}}
                  </router-link>
                </div>
              </div>

            </div>
            <div class=" half-btn pt-5 text-center">
              <router-link style="" class="btn btn-default btn-view-saved-order t18 decoration-none" to="/profile">{{$t('history.view_profile')}}
              </router-link>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-body t15">
            <h2 class="text-center t18 bold heading-line">{{$t('history.my_save_orders')}}</h2>
            <div id="accordionExample">
              <div class="panel-group">
                <div class="panel panel-info" v-for="(item , index) in saved_orders" :key="item.id">
                  <div class="panel-heading" role="button" @click="toggleAccordion(index, item)">
                    <h4 class="panel-title">{{ item.collection_time }}</h4>
                  </div>
                  <collapse v-model="showAccordion[index]">
                    <div class="panel-body">
                      <div class="row t14 pt10 pb10">
                        <div class="col-sm-4">
                          <div>{{$t('history.order_id')}} # {{ item.order_number }}</div>
                          <div class="text-red"> {{$t('history.date')}} {{ item.collection_time }}</div>
                        </div>

                        <div class="col-sm-4 ">
                          <div v-if="!item.details">
                              <img src="../assets/images/icons/loader-bike.gif" height="100" width="100"/>
                          </div>
                          <div class="order-item" v-for="(detail) in item.details" :key="genUniqueKey(detail)">
                            <div v-if="detail.Unavailable " class="not_available_bg">
                              <div class="not_available_block">{{$t('history.unavailable')}}</div>
                            </div>
                            <div v-if="detail.type ===0">
                              <div class="bold gold"><span v-if="detail.items.length >0">{{ convertString(detail.items[0].first.name + ' ' + detail.items[0].name) }}</span></div>
                              <div class="order-item__meta">
                                <ul class="list-inline t13">
                                  <li>{{ detail.items[0].first.second.name }}</li>
                                </ul>
                              </div>
                            </div>
                            <div v-else>
                              <div class="bold gold"><span>{{ convertString(detail.name) }}</span></div>
                              <div class="order-item__meta" v-for="mc in detail.items" :key="genUniqueKey(mc)">
                                <div class="bold "><span >{{ convertString(mc.first.name + ' ' + mc.name) }}</span></div>
                                <ul class="list-inline t13">
                                  <li>{{ mc.first.second.name }}</li>
                                </ul>
                              </div>
                            </div>
                            <div>
                              <!--<button class="btn btn-cta btn-danger mt-5 mb-5" @click="addtoCart(detail, detail.type)" v-if="detail.type != 3">{{$t('history.add_to_cart')}}</button>-->
                            </div>

                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="address">
                            <p v-if="item.order_type && item.order_type.toLowerCase() ==='c'"><strong>{{$t('carryout_from')}}: <br></strong> {{
                              item.outlet_address}} </p>
                            <div v-else>
                              <strong>{{$t('delivery_history')}}: <br></strong>
                              <span>
                                {{ item.address }}
                              </span>
                              <!-- <span v-else>
                                {{ item.address_name2 }}
                              </span> -->
                            </div>
                          </div>
                          <p v-if="item.Unavailable " class=" text-red">{{$t('history.order_unavailable')}}</p>
                          <router-link v-if="item.Unavailable " style="width: 100%" href=""
                                       class="btn btn-cta btn-danger" to="/">{{$t('history.back_to_menu')}}
                          </router-link>
                          <p v-if="!item.Unavailable " class=" text-red " v-bind:class="'error-reorder-'+item.order_number"></p>
                          <!--<a v-if="!item.Unavailable " href="" style="width: 100%" class="btn btn-cta btn-danger reorder">{{$t('history.reorder')}}</a>-->
                          <!--<a style="width: 100%" href="" class="btn btn-cta btn-danger mt-5">{{$t('delete')}}</a>-->
                        </div>
                      </div>
                    </div>
                  </collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
    moment
  },
  watch: {},
  mounted: function () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'user.history', 'type': 'user'}})

    let info = this.$auth.user()

    if (info.saved_orders) {
      this.saved_orders = info.saved_orders
    }

    if (info.birthday) {
      let _birthday = moment('2018/02/20', 'YYYY/MM/DD')
      let today = moment(new Date())
      if (_birthday.months() === today.months()) {
        this.birthday = 0
        this.isBirthday = true
      } else {
        this.birthday = _birthday.diff(today, 'months')
        if (this.birthday < 0) {
          this.birthday *= -1
        }
      }
    }
    this.getHistory()
  },
  data () {
    return {
      showAccordion: [],
      birthday: '',
      isBirthday: false,
      totalPage: 0,
      page: 0,
      saved_orders: []
    }
  },
  methods: {
    addtoCart (menu, type) {
      if (!this.checkPosition(this)) {
        let path = this.$route.fullPath.indexOf('/') != 0 ? this.$route.fullPath : this.$route.fullPath.substr(1)
        this.$router.push({'name': 'home', query: { isShowWarning: 1, callback: path }})
      }
      // pizza
      let item = {
        'group': {},
        'selling_start': '',
        'products': []
      }
      item.group['outlet_code'] = this.getOutletCode(this)
      item.group['tier'] = this.$disposition.getByKey(this, 'tier')
      item.group['order_type'] = this.$disposition.getByKey(this, 'orderType').charAt(0)
      item.group['order_time'] = this.getTimePickerDate(this)
      item.group['product_type'] = type
      item.group['combo_id'] = null
      item.group['quantity'] = 1
      if (type === 0) {
        let product = {}
        product.category_id = 'f3aa8c80-f52b-4467-aa07-a62dd34bc0b4'
        product.combo_group_id = null
        product.product_id = menu.items[0].uuid
        product.first_id = menu.items[0].first ? menu.items[0].first.uuid : null
        product.second_id = menu.items[0].first && menu.items[0].first.second ? menu.items[0].first.second.uuid : null
        product.toppings = {}
        product.toppings.remove = []
        product.toppings.extra = []
        for (let y in menu.items[0].topping.extra) {
          // product.toppings.extra.push(menu.items[0].topping.extra[y].id)
        }
        for (let r in menu.items[0].topping.remove) {
          // product.toppings.remove.push(menu.items[0].topping.remove[r].id)
        }
        item.products.push(product)

        this.$cart.insert(this, item)
      } else {
        item.group['combo_id'] = menu.uuid || '6a4177db-b557-4722-ac54-d9ca1a304178'
      }
    },
    getHistory () {
      let timezone = 'Asia/Tokyo'
      var vm = this
      this.page += 1
      let data = {
        limit: 10,
        page: this.page,
        sort_by: 'updated_at',
        order_by: 'desc'
      }
      this.axios.apiOrder.history(data, function (response) {
        if (response.data.data.items) {
          for (let i in response.data.data.items) {
            if (response.data.data.items[i].outlet_timezone) {
              timezone = response.data.data.items[i].outlet_timezone
            }
            let convertDate = vm.convertUTCToTimeZone(response.data.data.items[i].collection_time, timezone)
            vm.showAccordion.push(false)
            response.data.data.items[i].collection_time = convertDate.format('YYYY-MM-DD HH:mm:ss')
          }

          vm.saved_orders = (response.data.data.items)
          vm.totalPage = response.data.data.pagination.total_pages
        }
      }, function () {

      })
    },
    toggleAccordion (index, item) {
      var vm = this
      if (this.showAccordion[index]) {
        this.$set(this.showAccordion, index, false)
      } else {
        this.showAccordion = this.showAccordion.map((v, i) => i === index)
        if (!vm.saved_orders[index].details) {
          this.axios.apiOrder.getOrder(item.uuid, function (response) {
            vm.saved_orders[index].details = response.data.data.item.order_items
            vm.$forceUpdate(vm.saved_orders[index], 'details', response.data.data.item.order_items)
          }, function () {

          })
        }
      }
    }
  }
}
</script>
<style scoped>
  .form-section {
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
</style>
